.projects {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 100%;
    width: 100%;

    .project-wrap{
        margin: auto;
        padding:1rem;
    }

    .project {
        display: flex;
        flex-direction: column;
        color: #fff;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        pointer-events: all;
        height: 12rem;
        width: 42vw;
        max-width: 18rem;

        background-color: #353c4b;

        .project-icon {
            display: block;
            width: 6rem;
            height: 6rem;
            fill: rgb(101, 101, 117);
        }

        h1 {
            text-transform: capitalize;
            display: block;
            text-align: center;
        }
    }
}