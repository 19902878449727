.project {
    height: 100%;
    margin-bottom: 0 !important ;

    .column{
        height:100%
    }

    .project-content {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        display: flex;
	    flex-direction: column;

        .breadcrumb{
            flex: 0 1 auto;
            padding-top: 0.5rem;
            padding-left: 0.85rem;
            padding-right: 0.85rem;
            padding-bottom: 0.5rem;
            margin-bottom: 0;
            background-color: #343c3d;
        }

        .project-page{
            flex: 1 1 auto;
            overflow-y: hidden;
        }

        .model-viewer {
            height: 100%;
        }
    }
}

.mobile-panel-selection {
    width: 100%;
    .navbar-item{
        display: flex;
        place-items: center;
        place-content: center;
    }
}